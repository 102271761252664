import React from 'react';
import { Button, IconButton, Input, Tooltip } from '@material-ui/core';
import {createImageWithS3} from '../Data/Image';
import { Cancel, Delete } from '@material-ui/icons';

import {randomString} from '../Functions';

const EditableFileField = (props) => {
    let {isEditing, defaultValue, expandPreview=false, field, update, onLoad, className, style, buttonStyle, accept} = props;

    const [uniqueId,] = React.useState(`ImageHolder_${randomString(20)}`);
    const [isHover, setIsHover] = React.useState(false);
    const hoverTimer = React.useRef(undefined);

    const [previewShow, setPreviewShow] = React.useState(false);

    
    const [value, setValue] = React.useState(defaultValue);
    React.useEffect(()=>{
        setValue(defaultValue);
    },[defaultValue]);

    const onImagePutIn = React.useMemo(()=> async (event)=> {
        try{
            event.target.setAttribute('disabled', 'true');
            let filename = await createImageWithS3(event.target);
            let newValue = {};
            newValue[field] = filename;
            setValue(filename || '');
            await update(newValue)
        }catch(e){
            document.getElementById(uniqueId)?.removeAttribute('disabled');
            console.error(e)
        }
    }, [field, uniqueId, update]);

    return (<>
    {!isEditing
        ?(!value || ['jpg', 'jpeg', 'png', 'svg', 'gif'].indexOf(`.${value}`.split('.').reverse()[0].toLowerCase()) >=0)
            ?<div className={className} style={{position: 'relative', overflow: 'hidden', backgroundImage: `url('https://api.webtoon.today/thumb?mw=200&u=${encodeURIComponent(value || "https://static.webtoon.today/noimage.jpg")}')`, backgroundSize: 'cover', backgroundPosition: 'center', ...style}}>
                <img
                    style={{
                        width: '100%', height: '100%',
                        objectFit: 'contain', backdropFilter: 'blur(10px) brightness(0.8)',
                        ...(expandPreview?{cursor: 'pointer'}:{})
                    }}
                    src={`https://api.webtoon.today/thumb?mw=200&u=${encodeURIComponent(value || "https://static.webtoon.today/noimage.jpg")}`} alt={value}
                    {...expandPreview?{onClick:()=>{
                        setPreviewShow(true);
                        (document.querySelector(`#PreviewImg_${uniqueId}`))?.focus()
                    }}:{}}
                    onLoad={onLoad || (()=>{})} onError={(event)=>{
                        if (event.target) {
                            (event.target).src="https://static.webtoon.today/noimage.jpg"
                        }
                    }}
                />
            </div>
            :<Tooltip title={value}><div className={"SlideThumb"}>{`.${value}`.split('.').reverse()[0].toLowerCase()}</div></Tooltip>
        :value
            ?<div
                className={className} style={{display: 'block', position: 'relative', overflow: 'hidden', ...style}}
            >
                {(value && ['jpg', 'jpeg', 'png', 'svg', 'gif'].indexOf(`.${value}`.split('.').reverse()[0].toLowerCase()) >=0)
                    ?<img
                        style={{
                            width: '100%', height: '100%',
                            objectFit: 'contain',
                            ...(expandPreview?{cursor: 'pointer'}:{})
                        }}
                        src={value} alt={value}
                        {...expandPreview?{onClick:()=>{setPreviewShow(true)}}:{}}
                        onLoad={onLoad || (()=>{})}
                    />
                    :['zip'].indexOf(`.${value}`.split('.').reverse()[0]) >=0
                        ?<img style={{width: '100%', height: '100%', objectFit: 'contain'}} src={'https://static.webtoon.today/loading.gif'} alt={'https://static.webtoon.today/loading.gif'} onLoad={onLoad || (()=>{})}/>
                        :<div className={"SlideThumb"}>{`.${value}`.split('.').reverse()[0].toLowerCase()}</div>}
                    <IconButton
                        style={{position: 'absolute', top:-5, right:-5, width: 35, height: 35, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                        onClick={async ()=>{
                            let newValue = {};
                            newValue[field] = null;
                            setValue('');
                            await update(newValue)
                        }}
                    >
                        <Delete style={{fontSize: '1rem'}}/>
                    </IconButton>
                </div>
            :<div
                onDrop={(event) => {
                    event.preventDefault();
                    const files = event.dataTransfer.files;
                    
                    if (files && files.length > 0) {
                        event.stopPropagation();

                        const fileInput = document.getElementById(uniqueId);

                        fileInput.files = event.dataTransfer.files;

                        onImagePutIn({
                            target: fileInput,
                            nativeEvent: undefined,
                            currentTarget: fileInput,
                            bubbles: false,
                            cancelable: false,
                            defaultPrevented: false,
                            eventPhase: 0,
                            isTrusted: false,
                            preventDefault: ()=>false,
                            isDefaultPrevented: ()=>false,
                            stopPropagation: ()=>false,
                            isPropagationStopped: ()=>false,
                            persist: ()=>{},
                            timeStamp: 0,
                            type: ''
                        })
                    }
                }}
                onDragOver={(event) => {
                    event.preventDefault();
                    if (hoverTimer.current) {
                        clearTimeout(hoverTimer.current);
                    }

                    setIsHover(true);
                    hoverTimer.current = setTimeout(() => { setIsHover(false); }, 100);
                }}
                style={{overflow: 'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}
            >
                <Button variant={"outlined"} className={"AddNewSlide SlideThumb"} style={buttonStyle} onClick={()=>{
                    document.getElementById(uniqueId)?.click();
                }}>
                    +
                </Button>
                {isHover&&"파일을 드랍해서 업로드해보세요."}
                <Input
                    id={uniqueId}
                    className={className}
                    type={"file"}
                    style={{width:1,height:1, display:'none'}}
                    onChange={onImagePutIn}
                    name={"file"}
                    inputProps={{
                        accept: accept || ".gif,.jpg,.jpeg,.png,.svg"
                    }}
                />
            </div>
    }
    <div
        id={`PreviewImg_${uniqueId}`}
        tabIndex={0}
        style={{
            position:'fixed', top: 0, left: 0,
            width: '100%', height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            opacity: previewShow?1:0, zIndex: previewShow?2:-2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
        onClick={()=>{setPreviewShow(false)}}
        onKeyDown={(event) => {
            if(event.key === 'Escape'){
                setPreviewShow(false);
            }
        }}
    >
        {previewShow?<img
            loading='lazy'
            style={{maxWidth:'80%', maxHeight: '80%'}}
            src={value}
            alt={"none"}
            onClick={(event)=>event.stopPropagation()}
        />:<></>}
        <IconButton
            style={{position: 'absolute', right: 10, top: 50, color: 'white'}}
            onClick={()=>{setPreviewShow(false)}}
        ><Cancel/></IconButton>
    </div>
    </>
    );
}

export default EditableFileField;
