import React, { useState, useEffect } from "react";
import {read, askAsCOTStep1, askAsCOTStep2, AnswerChatDirect} from '../Data/ChatGPT';
import { Button, Drawer, IconButton, ListItem, Paper, TextField } from "@material-ui/core";
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

import './GPT.scss';
import { randomString } from "../Functions";
import { ChevronRight } from "@material-ui/icons";
import EditableFileField from "../Component/EditableFileField";

const tryJsonParse = (str, defaultValue={}) => {
    try{
        return JSON.parse(str);
    }catch(e){
        return defaultValue;
    }
}

const COT = () => {

    useEffect(()=>{
        document.title = "Anthropic COT: Chain of Thought";
    },[]);

    /**
     * @type {[
     *   {role: 'system'|'user'|'assistant', content: string}[],
     *   React.Dispatch<SetStateAction<{role: 'system'|'user'|'assistant', content: string}[]>>
     * ]}
     */
    const [chat, setChat] = useState([
        {role: 'system', content: `
        | You think through below steps and responses to the chat.
        |
        | 1. Always develop your logic in minimum 1 to maximum 5 steps.
        |     - Simple: take only 'conclusion' step. In this case you should label it as [Conclusion].
        |     - Complex: start by outlining your approach. Make it MECE or procedural 2-3 sub-problems. Label above this section "[Logic]\\n".
        | 2. For each sub-problem, label with "[Step {number}]\\n".
        | 3. Finally, Summarize your thought step and provide clear answer in reverse-pyramid style. Label above this "[Conclusion]\\n".
        |     - Aggregate all facts and imporant logics into [Conclusion](Let user doesn't need to read all arguments).
        |     - Make sure to provide summary of your thought process in [Conclusion].
        | 4. Respond only one part(logic / step / conclusion) at a time. Do not give multiple part at once.
        |     - It means If you give Logic, you should not give Step or Conclusion at the same time. If you can answer at a time, just give [Conclusion].
        | 5. If user asks to break logic, you can fix logic and label it "[Logic]\\n, and answer user's question".
        | 6. Finally you will provide requested output.
        |     - If user asked for a code, you will provide a code.
        |     - If user asked for a logical structure, you will provide a logical structure.
        |     - If user asked short answer, you will provide a short answer.
        |
        | Your repsonse should:
        | 1. not try to fill full tokens unless you have enough LLM tokens to respond.
        | 2. Use three to five meaningful words to consist first sentence of each paragraph(to make it easier to read).
        |     - Also, Your first sentence address the main point of the paragraph.
        | 3. Use Markdown syntax(with hypen and four space indentation, triple new line to seperate paragraph) and full sentence to be read fluently.
        | 4. Correct user's wrong information or nouns and keep the conversation on track.
        | 5. Never repeat parts of the question in your answer unless you are changing logic.
        | 6. Be matched with user's requested format.
        |     - If user asked for a code, you will provide a code.
        |     - If user asked for a logical structure, you will provide a logical structure.
        |     - If user asked short answer, you will provide a short answer.
        |`.replace(/ *\n +\|/g, '\n')}
    ]);
    const [content, setContent] = useState('');
    const [needToRetry, setNeedToRetry] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [, setElapsedTimer] = useState(null);

    /**
     * @type {[
     *   'waiting'|'first_response'|'critic'|'reflection',
     *   React.Dispatch<SetStateAction<'waiting'|'first_response'|'critic'|'reflection'>>
     * ]}
     * */
    const [stage, setStage] = useState('waiting');

    const [messageId, setMessageId] = useState(null);

    const [chatId,setChatId] = useState(randomString(32));

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [chatHistory, setChatHistory] = useState(tryJsonParse(localStorage.getItem('chatHistory'), []));

    const pollMessage = async (messageId) => {
        const [content, streaming] = await read({ messageId }) || [null];
        return { content, streaming };
    };

    const updateChat = (chat, messageId, content) => {
        const idx = chat.findIndex(row => row.messageId === messageId);
        if (idx >= 0) {
            return [
                ...chat.slice(0, idx),
                { ...chat[idx], content },
                ...chat.slice(idx + 1)
            ];
        }
        return [...chat, { role: 'assistant', content, messageId }];
    };

    const cleanupAndCheckConclusion = (content) => {
        setStage('waiting');
        setMessageId(null);
        setNeedToRetry(0);
        setElapsedTimer(timer => {
            clearInterval(timer);
            return null;
        });
      
        if (!content.includes("[Conclusion]") && (content.includes("[Logic]") || content.includes("[Step")) ){
            setTimeout(() => {
                setContent("(meta conversation-consider as pseudo system prompt) continue to reach the conclusion");
                document.getElementById('ask').click();
            }, 400);
        }
    };

    useEffect(()=>{
        if (!messageId){
            return;
        }

        const intervalLength = 200;
        const interval = setInterval(async () => {
            setNeedToRetry(prev => prev + intervalLength / 1000);
            const { content, streaming } = await pollMessage(messageId);

            if (content){
                setChat(chat => updateChat(chat, messageId, content));

                if (!streaming){
                    cleanupAndCheckConclusion(content);
                }
            }
        }, intervalLength);

        return ()=> {
            clearInterval(interval);
        }
    },[messageId])

    useEffect(()=>{
        if (needToRetry > 100){
            setMessageId(null);
        }
    },[needToRetry])
    
    useEffect(()=>{
        
        const timer = setTimeout(()=>{
            setChatHistory(chatHistory => {
                if (chat.filter(row => row.role === 'user').length === 0){
                    return chatHistory;
                }

                const firstContent = chat?.filter(row => row.role === 'user')[0]?.content;

                if (!firstContent){
                    return;
                }
                let title = "빈 대화";
                if (Array.isArray(firstContent)) {
                    title = firstContent.filter(row => row.type === 'string')[0].content.substring(0, 50);
                } else if (typeof firstContent === 'string'){
                    title = firstContent.substring(0, 50);
                }

                const newChatHistory = [
                    ...(chatHistory?.filter(chat => chat.chatId !== chatId) || []),
                    {
                        chatId,
                        title,
                        data: chat ?? [],
                        created_datetime: new Date().toLocaleString()
                    }
                ];

                localStorage.setItem('chatHistory', JSON.stringify(newChatHistory));
                return newChatHistory
            });
        }, 1000);
        return ()=>clearTimeout(timer);

    },[chat, chatId])


    return (<div style={{
        width: "calc(100% - 40px)", maxWidth: 800, minHeight: '100vh',
        margin: "0 auto", padding: 20, boxSizing: "border-box",
        backgroundColor:'rgba(245,245,245,1)', boxShadow: "0 0 10px rgba(0,0,0,0.1)"
    }}>
        {<Drawer open={isDrawerOpen} onClose={()=>setIsDrawerOpen(false)} anchor={"left"} style={{maxWidth: '80%'}}>
            {chatHistory?.map((chat, idx) =>
                <ListItem key={idx} button style={{whiteSpace: 'nowrap'}} onClick={()=>{
                    setChat(chat.data);
                    setChatId(chat.chatId);
                    setIsDrawerOpen(false);
                }}>
                    {`${chat.title}(${chat.created_datetime})`}
                </ListItem>
            )}
        </Drawer>}
        {!isDrawerOpen && <IconButton onClick={()=>setIsDrawerOpen(true)} style={{position: 'fixed', left: 10, top: 10, zIndex: 1000}}><ChevronRight/></IconButton>}
        {chat.filter(row => row.role !== 'system').map((message, idx) =>
        <Paper
            variant="outlined"
            style={{
                margin: 10, wordBreak:'keep-all', padding: 10,
                ...(message.role !== 'user' && !message.content.includes('[Conclusion]')?{color: 'rgba(200,200,200,1)'}:{})
            }} key={idx}
        >
            <div style={{fontWeight: 'bold'}}>
                {message.role} {(message.content === "(meta conversation-consider as pseudo system prompt) continue to reach the conclusion") && <span style={{fontSize: '80%', fontStyle:"italic"}}>{"계속"}</span> }
            </div>
            <div>
                {(message.content !== "(meta conversation-consider as pseudo system prompt) continue to reach the conclusion")
                && (Array.isArray(message.content)
                    ?message.content.map((content, idx) => content.type === 'string'
                        ?<Markdown key={`content_${idx}`} remarkPlugins={[gfm]}>{content.content}</Markdown>
                        :<img key={`content_${idx}`} src={content.source} alt={content.source} style={{maxWidth: '100%', maxHeight: '100%'}}/>)
                    :<Markdown remarkPlugins={[gfm]}>{message.content}</Markdown>) }
            </div>
        </Paper>)}
        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems: 'center', gap: 10}}>
            <TextField fullWidth multiline
                value={Array.isArray(content)?content.filter(row => row.type ==='string')[0].content:content} onChange={(e) => {
                    if (Array.isArray(content)){
                        setContent(content => [
                            ...content.filter(row => row.type !== 'string'),
                            {type: 'string', content: e.target.value}
                        ]);
                    } else {
                        setContent(e.target.value);
                    }
                }}
                onKeyDown={(e) => {
                    if(e.key === 'Enter' && e.ctrlKey && needToRetry === 0){
                        e.target.blur();
                        document.getElementById('ask').click();
                    }
                }}
            />
            <EditableFileField
                style={{maxHeight: 200, maxWidth: 200}}
                isEditing={true}
                field={"image"}
                defaultValue={Array.isArray(content)?content.filter(row => row.type ==='image')[0].source:null}
                update={({image})=>{
                    if (!image){
                        setContent(content => content.filter(row => row.type === 'string')[0]?.content ?? "");
                        return;
                    }

                    if (Array.isArray(content)){
                        setContent(content => [
                            ...content.filter(row => row.type === 'string'),
                            {type: 'image', source: image}
                        ]);
                    } else {
                        setContent(content => [
                            {type: 'image', source: image},
                            {type: 'string', content: content}
                        ])
                    }
                }}
            />
        </div>
        {needToRetry === 0
        ?<Button id={"ask"} variant="contained" color={"primary"} style={{margin: '10px 10px', width: 'calc(100% - 20px)'}}
            disabled={content.length === 0 && chat.length === 1}
            onClick={()=>{
                setNeedToRetry(1);
                const newChat = [
                    ...chat,
                    {role: 'user', content: content || "(meta conversation-consider as pseudo system prompt) continue to reach the conclusion"}
                ]
                setChat(newChat);
                setContent("");

                (async()=>{
                    setElapsedTime(0);
                    setElapsedTimer(setInterval(()=>{
                        setElapsedTime(elapsedTime => elapsedTime + 100);
                    }, 100));
                    let chat = newChat;
                    setStage('first_response');
                    let firstAnswer = await AnswerChatDirect({chat, max_tokens: 8192});
                    console.log("첫 응답입니다. 자기 비평을 통해 가다듬은 답안을 제시하겠습니다:\n"+firstAnswer);

                    setStage('critic');
                    let criticResp = await askAsCOTStep1({chat, firstAnswer, max_tokens: 8192});
                    console.log("자기비평입니다. 곧 최종 응답을 제시하겠습니다:\n"+criticResp);

                    setStage('reflection');
                    let reflectedMessageId = await askAsCOTStep2({chat, firstAnswer, criticResp, max_tokens: 8192});
                    setMessageId(reflectedMessageId);
                })();
            }}
        >{`${content.length > 0?"물어보기":"계속하기"} ${elapsedTime>0?`(지난 답변에서 ${`${elapsedTime / 1000}`.substring(0,4)} 초 소요)`:''}`}</Button>
        :`${stage === 'first_response'?'첫 응답을 생각중입니다: '
            :stage === 'critic'?'자기 비평 중입니다: '
            :stage === 'reflection'?'최종 응답을 생각중입니다: '
            :''}${`${elapsedTime / 1000}`.substring(0,4)}초 째 기다리는 중... (자세한 내용은 개발자 콘솔을 확인하세요)`}
    </div>);
}

export default COT;